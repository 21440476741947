* {
  box-sizing: border-box;
  /* 10px = 1rem */
  font-size: 62.5%;
  margin: 0;
  background-color: #EFF0EA;
}

.App {
  display: flex;
  flex-direction: column;


  padding: 0 20dvw;

}

.logo {



  max-height: 6.5rem;
  max-width: 18.7rem;
  margin-top: 2.6rem;

  & img {
    height: 100%;
    width: 100%;

    object-fit: contain;

  }

}

.flex-col {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: row;
  height: 23dvw;


}

.left-col,
.right-col {
  width: 30dvw;
}

.left-col {

  position: relative;
}

.right-col {

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.coming-soon {

  font-size: 4vmax;
  font-weight: bold;
  margin-bottom: 4.8rem;
}

.spring-2024 {
  font-size: 1.4dvw;
  font-weight: bold;
  margin-bottom: 1.4rem;

}

.descrip {
  width: 77%;
  font-size: 1dvw;
  font-weight: medium;
  line-height: 1.4dvw;
  margin-bottom: 8.8rem;
}

.contact-info {
  max-height: 30%;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;

  gap: 10rem;

}

.caption-caps {
  font-size: 0.6dvw;
  font-weight: bold;
  margin-bottom: 0.4rem;
  text-transform: uppercase;
}

.caption {
  font-size: 0.8dvw;
  font-weight: medium;
  margin-bottom: 0.5rem;
}

.inter-font {
  font-family: "Inter", sans-serif;
  font-weight: medium;
  color: #3e3731;
}

.inter-tight-font {
  font-family: "Inter Tight", sans-serif;
  font-weight: bold;
  color: #3e3731;

}






@media screen and (max-width: 1080px) {

  .App {
    padding: 0 2rem;
  }

  .flex-col {
    position: static;
    top: 0%;
    transform: translateY(0);
    flex-direction: column;



  }

  .logo {
    max-height: 6.5rem;
    max-width: 16.7rem;
    margin-top: 1.3rem;
    margin-bottom: 4.4rem;


    & img {
      width: 100%;
      height: 100%;
    }
  }

  .coming-soon {
    font-size: 7.7rem;
    font-weight: bold;
    margin-bottom: 4rem;
  }

  .spring-2024 {
    font-size: 2.6rem;
    font-weight: bold;
    margin: 0 0 1.4rem 0;
  }

  .descrip {
    /* width: 70%; */
    font-size: 2rem;
    line-height: 28px;
    font-weight: medium;
    margin: 0 0 8.8rem 0;
  }

  .contact-info {
    position: static;
    display: flex;

    gap: 8.5rem;
    margin: 2rem 0 0 0;
  }

  .caption-caps {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 0.6rem;


  }

  .caption {
    font-size: 1.8rem;
    font-weight: medium;
    margin-bottom: 0.75rem;
  }

  .left-col {

    width: 100%;
  }


  .right-col {


    width: 100%;
    margin-top: 8rem;

    outline: none;
  }
}

@media screen and (max-width: 430px) {

  .coming-soon {
    font-size: 6.6rem;
  }

  .spring-2024 {
    font-size: 2rem;
  }

  .descrip {
    font-size: 1.8rem;
    margin: 0 0 4.4rem 0;
  }

  .caption-caps {
    font-size: 1.2rem;
  }

  .caption {
    font-size: 1.4rem;
  }

  .contact-info {
    gap: 6rem;
  }
}

@media screen and (max-width: 280px) {

  .descrip {
    font-size: 1.6rem;
  }

  .caption-caps {
    font-size: 1rem;
  }

  .caption {
    font-size: 1.2rem;
  }
}